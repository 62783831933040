import { AppState } from "@auth0/auth0-react";
import Router from "next/router";

const REDIRECT_STATE = "redirectState";

export const setRedirectState = (appState: AppState) => {
  console.log("setting redirect state", appState);
  window.localStorage.setItem(REDIRECT_STATE, JSON.stringify(appState));
};

export const onRedirectCallback = (appState?: AppState | undefined) => {
  console.log("onRedirectCallback", { appState, currentUrl: Router.pathname });
  appState && setRedirectState(appState);
  Router.replace("/handleRedirect");
};
