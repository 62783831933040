"use client";

import { FinalMileMeta } from "@sourceful/shared-types";
import { createContext, FunctionComponent, useContext, useState } from "react";
import { getFinalMileMeta, saveFinalMileMeta } from "./helpers";

export interface FinalMileProviderInjectedProps {
  finalMile: FinalMileMeta;
  updateFinalMileMeta: (meta: FinalMileMeta) => void;
}

export interface FinalMileProviderProps {
  children?: React.ReactNode;
}

const FinalMileContext = createContext({} as FinalMileProviderInjectedProps);

const useFinalMileContext = () => useContext(FinalMileContext);

const FinalMileProvider: FunctionComponent<FinalMileProviderProps> = ({ children }) => {
  const initial = getFinalMileMeta();
  const [finalMile, setFinalMile] = useState<FinalMileMeta>(initial);

  const updateFinalMileMeta = (meta: FinalMileMeta) => {
    setFinalMile(meta);
    saveFinalMileMeta(meta);
  };

  return (
    <FinalMileContext.Provider
      value={{
        finalMile,
        updateFinalMileMeta,
      }}
    >
      {children}
    </FinalMileContext.Provider>
  );
};

export { FinalMileContext, FinalMileProvider, useFinalMileContext };
