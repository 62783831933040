import { useEffect } from "react";
import { useIdle } from "react-use";

export interface UseIdleLogoutProps {
  handleLogout: () => void;
  isAuthenticated: boolean;
  idleLogoutLimitSeconds?: number;
}

export const useIdleLogout = ({
  idleLogoutLimitSeconds,
  isAuthenticated,
  handleLogout,
}: UseIdleLogoutProps) => {
  const idleLimitMs = idleLogoutLimitSeconds ? idleLogoutLimitSeconds * 1000 : 30 * 60 * 1000; // 30m
  const isIdle = useIdle(idleLimitMs);

  useEffect(() => {
    if (isIdle && isAuthenticated) {
      console.debug(`User is idle after ${idleLimitMs / 1000} seconds, logging out`);
      handleLogout();
    }
  }, [isIdle, isAuthenticated, handleLogout]);
};
