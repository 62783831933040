"use client";

import { Auth0Provider } from "@auth0/auth0-react";
import { formatAuthCookieDomain } from "@sourceful/shared-utils/auth-utils";
import env from "../../../lib/utils/env";
import { onRedirectCallback } from "../AuthenticationProvider/helpers/redirectStateUtils";

const SOURCEFUL_ROOT_DOMAIN = env("ROOT_HOSTNAME");

// We need to wrap this as its a client component which cant be used in layout
export default function Auth0ProviderWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Auth0Provider
      domain={env("AUTH0_DOMAIN")}
      clientId={env("AUTH0_CLIENT_ID")}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback
      authorizationParams={{
        redirect_uri: env("BASE_URL"),
        audience: env("AUTH0_AUDIENCE"),
      }}
      cookieDomain={formatAuthCookieDomain(SOURCEFUL_ROOT_DOMAIN)}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
